<template>
  <div class="auth-wrapper auth-v2 forget-password">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>

      <b-col lg="6" class="main-image">
        <div>
          <b-img
            fluid
            :src="
              stage === 1
                ? imgEmail
                : stage === 2
                ? imgCode
                : stage === 3
                ? imgReset
                : imgEmail
            "
            alt="Login V2"
          />
        </div>
      </b-col>

      <b-col lg="6" class="d-flex align-items-center auth-bg">
        <b-col sm="10" lg="12" class="mx-auto">
          <!-- Forgot Password Form (stag 1)-->
          <SendEmailCode
            v-if="stage === 1"
            @changeFirstStageData="changeFirstStageData"
          />
          <!-- /Forgot Password Form (stag 1)-->

          <!-- Forgot Password Form (stag 2)-->
          <CheckCode
            v-if="stage === 2"
            :email="form_data.email"
            @changeStage="changeStage"
            @changeSecondStageData="changeSecondStageData"
          />
          <!-- /Forgot Password Form (stag 2)-->

          <!-- Forgot Password Form (stag 3)-->
          <ResetPasword
            v-if="stage === 3"
            :email="form_data.email"
            :pin_code="form_data.pin_code"
          />
          <!-- /Forgot Password Form (stag 3)-->
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BRow,
} from "bootstrap-vue";
import { required, email, min, max } from "@core/utils/validations/validations";
import SendEmailCode from "@/components/authentication/forgetPassword/sendEmailCode/SendEmailCode.vue";
import CheckCode from "@/components/authentication/forgetPassword/checkCode/CheckCode.vue";
import ResetPasword from "@/components/authentication/forgetPassword/resetPassword/ResetPasword.vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";

export default {
  name: "ForgetPassword",
  components: {
    SendEmailCode,
    CheckCode,
    ResetPasword,
    BLink,
    BForm,
    BImg,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    VuexyLogo,
  },
  data() {
    return {
      logoImg: require("@/assets/images/logo/logo-jood.png"),
      imgEmail: require("@/assets/images/auth/email.svg"),
      imgCode: require("@/assets/images/auth/code.svg"),
      imgReset: require("@/assets/images/auth/reset-password.svg"),
      stage: 1,
      required,
      min,
      max,
      errors: [],
      form_data: {
        email: "",
        pin_code: null,
      },
    };
  },
  methods: {
    changeStage(stage) {
      this.stage = stage;
    },
    changeFirstStageData(email) {
      this.stage = 2;
      this.form_data.email = email;
    },
    changeSecondStageData(pin_code) {
      this.stage = 3;
      this.form_data.pin_code = pin_code;
    },
  },
};
</script>

<style lang="scss" src="./_forgetPassword.scss" scoped />
